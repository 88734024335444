
import { Component, Vue } from 'vue-property-decorator';
import Expand from '@/components/Charts/widgetInfo/Expand.vue';

@Component({
  components: {
    'expand-button': Expand,
  },
})
export default class CareCuttingHeadEventsTimeline extends Vue {}
